(function ($) {
    "use strict";

    let jobs_require = function () {
        if (typeof jQuery.fn.isotope !== "undefined") {
            _jobs_core.init();
        } else {
            // Check again after a short delay
            setTimeout(jobs_require, 50);
        }
    };

    const _jobs = $(".jobs--main");
    const _jobItem = $(".job--list-item-link");

    const _jobsList = $(".jobs--list");
    const _jobsSort = $(".jobs--sort");
    const _jobsCurrentSort = $(".jobs--sort span");
    const _jobsSortItem = $(".jobs--sort-container ul li");
    const _jobsFilterItem = $(".jobs--filter-container ul li");
    const _jobCount = $("#js--jobs-count");
    const _jobsFilterList = $(".jobs--filter-list");
    const _jobsMobileFilterContainer = $(".jobs--mobile-filter-container");
    const _jobsMobileFilterList = $(".jobs--mobile-active-filters");
    const _jobsMobileFilterButton = $(".jobs--mobile-filter-button");
    const _jobsMobileFilterItem = $(".jobs--mobile-filter-container ul li");
    const _jobsResetFilter = $("#js--reset-filter");
    const _jobsChipContainer = $(".jobs--chip-container");
    const _jobsLoader = $(".jobs-loader");
    const _jobsSearchbar = $("#js--jobs-searchbar");
    const _jobsSearchButton = $(".jobs--searchbar-button");
    const _jobsSidebar = $(".jobs--sidebar");
    const _loadMore = $(".js--jobs--load-more");

    const searchTimeoutTime = 500;

    let _grid = null;
    let activeFilters = {
        regionen: [],
        taetigkeiten: [],
        erscheinungsdatum: [],
        sort: "",
        search: ""
    };

    let isLoading = false;
    let nextPage = 2;
    let has_more_jobs = true;
    let no_more_jobs = false;
    let searchTimeout;


    // Check if the jobs element exists
    if (!_jobs.length) {
        return false;
    }

    let _JC = null;

    let _jobs_core = {
        init: function () {
            _JC = this;
            this.setup();
            this.events();
            this.mobile_events();
        },

        setup: function () {
            // Setup isotope grid
            _grid = _jobsList.isotope({
                itemSelector: ".job--list-item-link",
                layoutMode: "fitRows",
                percentPosition: true,
                transitionDuration: 0,
                resizeContainer: true,
            });
        },

        load: function (reset = false) {
            if (isLoading) {
                return false;
            }

            isLoading = true;

            $.ajax({
                url: jobboerseAjax.ajaxurl,
                type: "POST",
                data: {
                    action: "fetch_jobs",
                    page: nextPage,
                    filters: activeFilters
                },

                // Show loading spinner
                beforeSend: function () {
                    _jobsLoader.addClass("is-loading");
                },

                //
                success: function (response) {
                    if (reset) {
                        _grid.empty();
                        _grid.isotope("reloadItems").isotope({sortBy: "original-order"});

                    }

                    if (response.jobs && response.jobs.length > 0) {
                        let _items = _JC.create_items(response.jobs);
                        $.each(_items, function (index, item) {
                            _grid.append(item).isotope("appended", item);
                            _grid.isotope("layout");
                        });

                        nextPage++;
                    } else {
                        no_more_jobs = true;
                        if (reset) {
                            _grid.html("<p class=\"no-jobs\">Keine Jobs gefunden.</p>");

                        }
                    }

                    if (!response.has_more_jobs) {
                        has_more_jobs = false;
                        _loadMore.hide();
                    } else {
                        has_more_jobs = true;
                        _loadMore.show();
                    }

                    let _jobListItems = $('.jobs--list .job--list-item-link');

                    // Filter the elements to include only those that are not hidden (display: none)
                    let visibleJobListItems = _jobListItems.filter(function () {
                        return $(this).css('display') !== 'none';
                    });

                    // Count the visible job list items
                    let count = visibleJobListItems.length;

                    if (response.next_page_posts_count && response.next_page_posts_count > 0) {
                        _loadMore.find("span").text(response.next_page_posts_count + " von " + (response.total - count));
                    }


                    isLoading = false;
                    _jobsLoader.removeClass("is-loading");

                    _jobsSearchbar.prop("disabled", false);
                    _grid.isotope("layout");
                    _JC.countItems(response.total);
                },

                error: function () {
                    console.error("There was an error loading the jobs.");
                    isLoading = false;
                    _jobsLoader.removeClass("is-loading");
                }
            });
        },

        create_items: function (jobs) {

            let _items = jobs.map(function (job) {

                let pinned = job.pinned
                    ? "<div class=\"job-pinned\"><i class=\"icon icon-pinned\"></i></div>"
                    : "";

                let borderStyle = job.border ? "style=\"" + job.border + ";\"" : "";

                let taetigkeitenList = job.taetigkeiten
                    ? job.taetigkeiten
                        .map(function (term) {
                            return "<p class=\"jobs--item-category\">" + term + "</p>";
                        })
                        .join("")
                    : "";

                let regionenList = job.regionen
                    ? job.regionen
                        .map(function (term) {
                            return "<p class=\"jobs--item-region\" style=\"display: none;\">" + term + "</p>";
                        })
                        .join("")
                    : "";

                return $(`<a class="job--list-item-link" href="${job.link}" target="_blank">
                <div class="jobs--list-item" ${borderStyle}>
                    ${pinned}
                    <div class="jobs--item-container">
                        <div class="jobs--item-regionen-container" style="display: none;">
                            ${regionenList}
                        </div>
                        <div class="jobs--item-category-container">
                            ${taetigkeitenList}
                        </div>
                        <h3>${job.title}</h3>
                        <div class="jobs--item-info">
                            <div class="info-inner-container">
                                <div class="icon-container">
                                    <i class="icon icon-business"></i>
                                </div>
                                <p class="job--company">${job.company}</p>
                            </div>
                            <div class="info-inner-container">
                                <div class="icon-container">
                                    <i class="icon icon-location-pin"></i>
                                </div>
                                <p class="job--location">${job.location}</p>
                            </div>
                        </div>
                        <p class="job--description">${job.description}</p>
                        <p class="job--date">${job.date}</p>
                    </div>
                    <div>
                        <img src="${job.image}" alt="Job image">
                    </div>
                </div>
            </a>`);
            });
            _items.join("");
            return _items;
        },

        update_ui: function () {
            _jobsFilterList.empty();
            _jobsMobileFilterList.empty();

            let hasActiveFilters = false;

            Object.keys(activeFilters).forEach(function (category) {
                if (Array.isArray(activeFilters[category])) {
                    // Ensure the category is an array
                    activeFilters[category].forEach(function (filterId) {
                        let chipText = "";
                        // Find the filter name
                        _jobsFilterItem.each(function () {
                            if ($(this).data("filter-id") === filterId) {
                                chipText = $(this).text().trim();
                            }
                        });

                        _jobsMobileFilterItem.each(function () {
                            if ($(this).data("filter-id") === filterId) {
                                chipText = $(this).text().trim();
                            }
                        });

                        // Chip Desktop
                        let chip = $("<div>", {
                            class: "filter--chip",
                            text: chipText
                        }).click(function () {
                            _JC.filter__category(filterId, category, false);
                            _jobsFilterItem.each(function () {
                                if ($(this).data("filter-id") === filterId) {
                                    $(this).removeClass("is--selected");
                                }
                            });
                            _jobsMobileFilterItem.each(function () {
                                if ($(this).data("filter-id") === filterId) {
                                    $(this).removeClass("is--selected");
                                }
                            });
                        });
                        let closeIcon = $("<i>", {class: "icon icon-close"});

                        chip.append(closeIcon);
                        _jobsFilterList.append(chip);

                        // Chip Mobile
                        let chipMobile = $("<div>", {
                            class: "filter--chip",
                            text: chipText
                        }).click(function () {
                            _JC.filter__category(filterId, category, false);
                            _jobsFilterItem.each(function () {
                                if ($(this).data("filter-id") === filterId) {
                                    $(this).removeClass("is--selected");
                                }
                            });
                            _jobsMobileFilterItem.each(function () {
                                if ($(this).data("filter-id") === filterId) {
                                    $(this).removeClass("is--selected");
                                }
                            });
                        });
                        let closeIconMobile = $("<i>", {class: "icon icon-close"});

                        chipMobile.append(closeIconMobile);
                        _jobsMobileFilterList.append(chipMobile);

                        hasActiveFilters = true;
                    });
                } else if (
                    activeFilters[category] &&
                    category !== "sort" &&
                    category !== "search"
                ) {
                    // Handle non-array categories
                    let chipText = activeFilters[category] + " (" + category + ")";
                    let chip = $("<div>", {class: "filter--chip", text: chipText});
                    let closeIcon = $("<i>", {class: "icon icon-close"}).click(
                        function () {
                            activeFilters[category] = "";
                            _JC.update_ui();
                            nextPage = 1; // Reset nextPage to 1
                            _JC.load(true);
                        }
                    );
                    chip.append(closeIcon);
                    _jobsChipContainer.append(chip);
                    hasActiveFilters = true;
                }
            });

            _jobsResetFilter.css(
                "display",
                hasActiveFilters ? "block" : "none"
            );
            _jobsChipContainer.css(
                "display",
                hasActiveFilters ? "flex" : "none"
            );
        },

        layout: function () {
            _grid.isotope("layout");
        },

        countItems: function (count = 0) {
            /*let _jobListItems = $('.jobs--list .job--list-item-link');

            // Filter the elements to include only those that are not hidden (display: none)
            let visibleJobListItems = _jobListItems.filter(function () {
                return $(this).css('display') !== 'none';
            });*/

            // Count the visible job list items
            //let count = visibleJobListItems.length;

            _jobCount.text(count + " Treffer");


        },

        filter__category: function (filterId, category, isSelected, mobile = false) {
            // Ensure the category array exists
            if (!Array.isArray(activeFilters[category])) {
                console.error(
                    "Category " + category + " is not an array in activeFilters."
                );
                return;
            }

            let index = activeFilters[category].indexOf(filterId);
            if (isSelected) {
                if (index === -1) {
                    // Only add if not already in the array
                    activeFilters[category].push(filterId);
                }
            } else {
                if (index !== -1) {
                    // Only remove if exists in the array
                    activeFilters[category].splice(index, 1);
                }
            }
            _JC.update_ui();

            if (!mobile) {
                nextPage = 1; // Reset nextPage to 1
                _JC.load(true);
            }
        },

        filter__sort: function (sortId, sortName, isSelected) {
            if (isSelected) {
                _jobsCurrentSort.text(sortName);
            }

            activeFilters["sort"] = sortId;
            //console.log(activeFilters['sort']);
            nextPage = 1; // Reset nextPage to 1
            _JC.load(true);
        },

        filter__search: function () {
            let searchQuery = $("#js--jobs-searchbar").val().toLowerCase();

            if (searchQuery.length >= 4) {
                activeFilters["search"] = searchQuery;
                nextPage = 1; // Reset nextPage to 1
                _JC.load(true);
            }

            if (searchQuery.length === 0) {
                activeFilters["search"] = "";
                nextPage = 1; // Reset nextPage to 1
                _JC.load(true);
            }

        },

        // Detect Page Events (Scroll)
        events: function () {
            // Disable search input while loading
            if (isLoading === true) {
                _jobsSearchbar.prop("disabled", true);
            }

            // Filter Sort Trigger
            _jobsSortItem.click(function (e) {
                e.preventDefault();
                let $this = $(this);
                let sortId = $this.data("sort");
                let sortName = $(this).text();
                let isSelected = !$(this).hasClass("is--selected");
                $(this).toggleClass("is--selected");
                _JC.filter__sort(sortId, sortName, isSelected);
            });

            // Filter Category Trigger
            _jobsFilterItem.click(function (e) {
                e.preventDefault();
                let $this = $(this);
                let filterId = $this.data("filter-id");

                let category = $this.data("filter-category");
                let isSelected = !$(this).hasClass("is--selected");
                $(this).toggleClass("is--selected");
                _JC.filter__category(filterId, category, isSelected, false);
            });

            // Search Submit Trigger
            _jobsSearchButton.click(function (e) {
                e.preventDefault();
                _JC.filter__search();
            });

            _jobsSearchbar.on('input', function () {
                // Clear the existing timeout, if any
                clearTimeout(searchTimeout);

                // Set a new timeout
                searchTimeout = setTimeout(function () {
                    _JC.filter__search();
                }, searchTimeoutTime);
            });

            // Reset Filter Trigger
            _jobsResetFilter.click(function (e) {
                e.preventDefault();
                Object.keys(activeFilters).forEach(function (category) {
                    if (Array.isArray(activeFilters[category])) {
                        activeFilters[category] = [];
                    } else {
                        activeFilters[category] = ""; // Reset non-array filters
                    }
                });
                _jobsFilterItem.removeClass("is--selected");
                nextPage = 1; // Reset nextPage to 1
                _JC.load(true);
                _JC.update_ui();
            });

            // Sidebar Filter Menu Toggle
            _jobsSidebar.on("click", ".jobs--filter-head", function () {
                $(this).parent().find("ul").toggleClass("is--active");
                $(this).find(".icon").toggleClass("is--active");
            });

            // Sort Dropdown Trigger
            _jobsSort.hover(function () {
                $(this).find(".jobs--sort-container").toggleClass("is--active");
            });

            // Load jobs on click load more

            _loadMore.click(function () {
                if (has_more_jobs) {
                    _JC.load();
                }
            });

        },

        mobile_events: function () {
            // Mobile Filter Trigger Open
            _jobsMobileFilterButton.click(function (e) {
                e.preventDefault();
                _jobsMobileFilterContainer.toggleClass("is--active");
                $("body").toggleClass("is--no-scroll");
            });

            // Mobile Filter Trigger Close
            _jobsMobileFilterContainer
                .find(".icon-close")
                .click(function (e) {
                    e.preventDefault();
                    _jobsMobileFilterContainer.removeClass("is--active");
                    $("body").toggleClass("is--no-scroll");
                });

            // Mobile Filter Submit
            $(".jobs--mobile-filter-submit").click(function (e) {
                e.preventDefault();
                nextPage = 1; // Reset nextPage to 1
                _JC.load(true);
                _jobsMobileFilterContainer.removeClass("is--active");
                $("body").toggleClass("is--no-scroll");
            });

            // Mobile Filter Menu Toggle
            _jobsMobileFilterItem.click(function (e) {
                e.preventDefault();
                let $this = $(this);

                if ($this.data("sort")) {
                    let sortId = $this.data("sort");
                    let sortName = $(this).text();
                    let isSelected = !$(this).hasClass("is--selected");
                    $(this).toggleClass("is--selected");

                    _JC.filter__sort(sortId, sortName, isSelected);
                } else {
                    let filterId = $this.data("filter-id");
                    let category = $this.data("filter-category");
                    let isSelected = !$(this).hasClass("is--selected");
                    $(this).toggleClass("is--selected");

                    _JC.filter__category(filterId, category, isSelected, true);
                }
            });
        }


    };

    if (window.ONBN.modules.jobs === true) {
        jobs_require();
    }
    
})(jQuery);
