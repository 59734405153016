(function($) {
  "use strict";

  let require_gallery_slideout = function() {
    _gallery.init();
  };

  let _GSO = null;
  let _gallery = {

    __Selector: ".js--gallery-box",

    init: function() {
      if (window.ONBN.modules.gallery) {
        _GSO = this;
        _GSO.setup();
        _GSO.events();
      }
    },

    setup: function() {
      document.querySelectorAll(_GSO.__Selector).forEach(_GSO.build);
    },

    build: function(item) {

      $(item).on("click", function() {
        // Navigate to the URL in the same window
       // window.location.href = $(this).data("url");
      });

    },


    events: function() {

      let referrer = document.referrer;
      let gallery_close = $(".js--gallery-close");
      let is_article = referrer.includes("sol.de") || referrer.includes("tonight.de") || referrer.includes("sol.local");
      // Change the text based on the referrer
      if (is_article) {
        gallery_close.html("<i class=\"icon icon-arrow-right-alt\"></i>Zurück zum Artikel");
      } else {
        gallery_close.html("<i class=\"icon icon-arrow-right-alt\"></i>Zurück zur Startseite");
      }

      if (is_article) {
        // Go back to the previous page
        gallery_close.attr('href',referrer);
      }


      let galleryTitle = $('body').find('h1').text() || "Kein Titel";

      // When either the image or the button is clicked
      // Initialize Glightbox

      // Function to trigger the lightbox
      $('img[data-lightbox]').on('click', function () {
        var imageUrl = $(this).data('lightbox');
        openLightbox(imageUrl, galleryTitle + " - Lightbox");
      });

      // Event for zoom button click
      $('.js--gallery-zoom').on('click', function () {
        var imageUrl = $(this).parent().find('img').data('lightbox');
        openLightbox(imageUrl, galleryTitle + " - Lightbox");
      });

      let observer = new IntersectionObserver(
        (entries, observer) => {
          entries.forEach((entry) => {
            let boxIndex = parseInt(entry.target.getAttribute("data-index"));



            if (entry.isIntersecting  && boxIndex % 3 === 0) { // && boxIndex >= 3
              const page = Math.floor(boxIndex / 3);

              // Check if the analytics function exists and call it
              if ($.isFunction(window.callAnalytics)) {
                callAnalytics(galleryTitle + " | Seite: " + page);
              }
            }



            // Only process the element when it becomes visible in the viewport
            if (entry.isIntersecting) {
              //console.log(`Element index: ${boxIndex}`);


              // Uncomment this block for further logic to load adverts
              if (
                !entry.target.classList.contains("is-loaded") &&
                entry.target.classList.contains("advert")
              ) {
                let advertID = entry.target.id;
                let advertTarget = `#${advertID}`;

                // Add the "is-loaded" class to mark the element as processed
                entry.target.classList.add("is-loaded");

                console.log(`Detecting next advert: ${advertID} | Found: ${$(advertTarget).length}`);
                window.dispatchEvent(new CustomEvent("advert__gallery_sidebar", { detail: { ID: advertID } }));
              }


              // Once the element has been processed, you can stop observing it
              observer.unobserve(entry.target);
            }
          });
        },
        {
          root:null,
          rootMargin: "0px 0px -200px 0px", // Increase bottom margin to trigger closer to viewport
          threshold: [0, 0.25, 0.5, 0.75, 1] // Detect when the element is partially visible at different thresholds
        }
      );

      let G_items = document.querySelectorAll(".gallery-slideout > .figure,.gallery-slideout > .advert");

      G_items.forEach((box, index) => {
       // Start observing each item
        observer.observe(box);
        box.setAttribute("data-index", index + 1);
      });



    }


  };

  require_gallery_slideout();
})(jQuery);



