(function ($) {
    "use strict";

    let hashtags_require = function () {
        if (typeof Swiper !== "undefined") {
            _hashtags_core.init();
            //console.log("Hashtags module loaded");
        } else {
            //console.log("Hashtags module not loaded");
            // Check again after a short delay
            setTimeout(hashtags_require, 50);
        }
    };

    const headerAdvert = $(".header-advert");

    let lastScrollTop = 0;

    let hashTags = null;
    let menu = null;
    let subMenu = null;

    let _HC = null;

    let _hashtags_core = {
        init: function () {
            _HC = this;
            this.setup();
            this.events();
        },

        setup: function () {
            hashTags = new Swiper(".js--hashtags-slider", {
                slidesPerView: "auto",
                freeMode: true,
                pagination: false
            });

            menu = new Swiper(".nav--inner", {
                slidesPerView: "auto",
                freeMode: true,
                pagination: false,
                wrapperClass: "js--nav",
                slideClass: "depth-0"
            });

            subMenu = $("<div>", {
                id: "submenu",
                class: "submenu-container"
            });
            $("header.main--header").after(subMenu);
        },

        updateLayout: function (scrollTop) {
            if ($(window).width() < 500) {
                var newPadding = scrollTop > 100 ? "115px" : "185px"; // Adjusted values for smaller screens
                headerAdvert.css("display", scrollTop > 100 ? "none" : "block");
                $("body").css("margin-top", newPadding);
                $(".mobile--header-menu").css("padding-top", newPadding);
            }
        },

        events: function () {
            if (headerAdvert.length) {
                // Check the scroll position on load
                _HC.updateLayout($(window).scrollTop());

                // Update layout on scroll
                $(window).scroll(function () {
                    var st = $(this).scrollTop();
                    _HC.updateLayout(st);
                    lastScrollTop = st;
                });

                // Update layout when window resizes
                $(window).resize(function () {
                    _HC.updateLayout(lastScrollTop);
                });
            }

            $(".js--nav li").hover(function () {
                if ($(this).hasClass("menu-item-has-children")) {
                    var offset = $(this).offset();
                    var topPos = offset.top; // 20px under the link
                    var leftPos = offset.left;

                    subMenu.css({
                        top: topPos + "px",
                        left: leftPos + "px"
                    });

                    // Copy the ul.sub-menu to the new div
                    var copiedMenu = $(this).children("ul.sub-menu").clone();
                    subMenu.html(copiedMenu);

                    $("#submenu").addClass("is-open");
                } else {
                    $("#submenu").removeClass("is-open", function () {
                        $(this).html("");
                    });
                }
            });

            $("body").click(function () {
                $("#submenu").removeClass("is-open", function () {
                    $(this).html("");
                });
            });

            $(".js--menu").on("click", function () {
                // Toggle 'is-open' class on the menu button itself
                $(this).toggleClass("is-open");

                // Find the 'header.main--header' and toggle 'is-open' on it
                $("header.main--header").toggleClass("is-open");
            });

        },
    }

    if (window.ONBN.modules.hashtags === true) {
        hashtags_require();
    }

})(jQuery);