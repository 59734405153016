(function ($) {
    "use strict";

    let rss_require = function () {
        _rss_core.init();
    };


    let _RSSC = null;

    let _rss_core = {
        init: function () {
            _RSSC = this;
            this.events();
        },

        timeAgo: function (dateString) {
            let date = new Date(dateString);
            let seconds = Math.floor((new Date() - date) / 1000);

            let intervals = [
                {name: "Jahr", plural: "Jahre", seconds: 31536000},
                {name: "Monat", plural: "Monate", seconds: 2592000},
                {name: "Tag", plural: "Tage", seconds: 86400},
                {name: "Stunde", plural: "Stunden", seconds: 3600},
                {name: "Minute", plural: "Minuten", seconds: 60},
                {name: "Sekunde", plural: "Sekunden", seconds: 1}
            ];

            for (var i = 0; i < intervals.length; i++) {
                var interval = intervals[i];
                var count = Math.floor(seconds / interval.seconds);
                if (count >= 1) {
                    return (
                        count +
                        " " +
                        (count > 1 ? interval.plural : interval.name) +
                        " her"
                    );
                }
            }
        },

        getRSSFeed: function (section, successCallback) {
            // Get the feed data from the data-info attribute
            let info = $(section).data("info");

            /* // Get current time
            var currentTime = new Date();

            // Check if the stored feed and timestamp exist and if the feed was fetched less than 30 minutes ago
            /* if (storedFeed && storedTimestamp && (currentTime.getTime() - new Date(storedTimestamp).getTime()) < 1800000) {
            // Call the success callback with the stored RSS feed
                console.log('Calling Stored Feed');
                successCallback(storedFeed, section);
            } else  { */

            // Fetch the RSS feed
            let proxyUrl = info.feed;
            $.ajax({
                url: proxyUrl,
                dataType: "xml",
                async: false,

                success: function (data) {
                    let feed = [];
                    let count = 0; // Initiate a counter

                    $(data)
                        .find("item")
                        .each(function () {
                            if (count >= 6) {
                                return false;
                            }

                            let $this = $(this);

                            let categories = $this
                                .find("category")
                                .map(function () {
                                    return $(this).text().trim();
                                })
                                .get()
                                .filter((text) => text !== "");

                            let item = {
                                title: $this.find("title").text(),
                                link: $this.find("link").text(),
                                pubDate: _rss_core.timeAgo($this.find("pubDate").text()),
                                img: $this.find("enclosure").attr("url"),
                                category: categories.pop() || ""
                            };
                            feed.push(item);

                            count++;
                        });

                    // Cookies.set('rss_feed_' + info.id, JSON.stringify(feed), {expires: 7});
                    // Cookies.set('rss_timestamp_' + info.id, currentTime.toISOString(), {expires: 7});

                    // Call the success callback with the fetched RSS feed
                    successCallback(feed, section);
                },
                error: function (e) {
                    console.log("Failed to fetch RSS feed");
                    console.log("XML reading Failed: ", e);
                }
            });
        },

        fillTemplate: function (feed, section) {
            // Define the function to fill the HTML template with the RSS feed data

            const info = $(section).data("info");

            // Loop through each post in the feed
            for (var i = 0; i < feed.length; i++) {
                // Get the current post
                let post = feed[i];

                // Get the corresponding article element in the HTML template
                let $article = $(section).find(".post--element").eq(i);

                const href = new URL(post.link);
                const utmParams = new URLSearchParams(info.UTM);
                utmParams.forEach((value, key) =>
                    href.searchParams.append(key, value)
                );

                // Fill the article element with the post data
                $article.find("a").attr("href", href);
                $article.find(".post--image img").attr("src", post.img);
                $article.find(".post--category span").text(post.category);
                $article.find(".post--title h2").text(post.title);
                $article
                    .find(".post--time")
                    .html("<time>" + post.pubDate + "</time>");
            }

        },

        events: function () {
            // Define the function to add event listeners
            $(".js--rss-feed").each(function (index, element) {
                _rss_core.getRSSFeed(element, _rss_core.fillTemplate);
            });
        },

    };

    if (window.ONBN.modules.rss === true) {
        rss_require();
    }

})(jQuery);