(function ($) {
    "use strict";

    let _ADS = null;
    let adfree = false;

    let _ads_core = {
        init: function () {
            _ADS = this;
            this.stop__ads();
        },

        stop__ads: function () {
            if ($("body").hasClass("stop-support")) {
                adfree = true;
            }
        },

    };

    if (window.ONBN.modules.ads === true) {
        _ads_core.init();
    }


})(jQuery);