(function ($) {
    "use strict";

    const breadcrumbs = $('.js--breadcrumbs');
    const post_time = $('.post--time');
    const post_time_ago = $('.post--time-ago');

    let _ARTICLE = null;

    let require_article = function () {
        if (typeof Swiper !== "undefined") {
            _article_core.init();
        } else {
            // Check again after a short delay
            setTimeout(require_article, 50);
        }
    };


    let _article_core = {
        init: function () {
            _ARTICLE = this;
            this.share_btn();
            //this.translate_btn();
            this.post_time_ago();
            this.mobile_swiper();
        },

        timeago: function (date) {
            const seconds = Math.floor((new Date() - date) / 1000);
            let interval = Math.floor(seconds / 31536000);

            if (interval >= 1) {
                return "vor " + interval + " Jahr" + (interval > 1 ? "en" : "");
            }
            interval = Math.floor(seconds / 2592000);
            if (interval >= 1) {
                return "vor " + interval + " Monat" + (interval > 1 ? "en" : "");
            }
            interval = Math.floor(seconds / 86400);
            if (interval >= 1) {
                return "vor " + interval + " Tag" + (interval > 1 ? "en" : "");
            }
            interval = Math.floor(seconds / 3600);
            if (interval >= 1) {
                return "vor " + interval + " Stunde" + (interval > 1 ? "n" : "");
            }
            interval = Math.floor(seconds / 60);
            if (interval >= 1) {
                return "vor " + interval + " Minute" + (interval > 1 ? "n" : "");
            }
            return "Gerade eben";
        },

        share_btn: function () {
            let modalOpen = false;

            let share_btn = '<div class="post--share-container"><div class="post--share-btn"><i class="icon icon-share-alt"></i></div></div>';
            let share_list = '<div class="post--share-list" style="display: none;"><div class="social--btn social--close"><i class="icon icon-close-alt"></i><span class="social--tooltip">Schließen</span></div><div class="social--btn social--link"><i class="icon icon-link"></i><span class="social--tooltip">Link kopieren</span></div><div class="social--btn social--whatsapp"><i class="icon icon-whatsapp"></i><span class="social--tooltip">Whatsapp</span></div><div class="social--btn social--facebook"><i class="icon icon-facebook"></i><span class="social--tooltip">Facebook</span></div><div class="social--btn social--x"><i class="icon icon-x-twitter"></i><span class="social--tooltip">X (Twitter)</span></div></div>'

            breadcrumbs.append(share_btn);
            $('.post--share-container').append(share_list);

            $('.post--share-btn').on('click', function () {
                if (modalOpen) {
                    $('.post--popup').remove();
                    $('.main--header').css('z-index', '0');
                    modalOpen = false;
                    $('.post--share-container').css('z-index', '0');
                    $('.post--share-list').hide();
                    return;
                } else {
                    $('main').append('<div class="post--popup"></div>');
                    $('.main--header').css('z-index', '1');
                    $('.post--share-container').css('z-index', '1000');
                    $('.post--share-list').show();
                    modalOpen = true;
                }
            });

            $('.social--close').on('click', function () {
                if (modalOpen) {
                    $('.post--popup').remove();
                    $('.main--header').css('z-index', '0');
                    modalOpen = false;
                    $('.post--share-container').css('z-index', '0');
                    $('.post--share-list').hide();
                    return;
                }
            });

            // Copy Link
            $('.social--link').on('click', function () {
                let copyText = window.location.href;
                navigator.clipboard.writeText(copyText);
                alert('Link wurde kopiert!');
            });

            // Share on Whatsapp
            $('.social--whatsapp').on('click', function () {
                let shareText = window.location.href;
                let shareUrl = 'whatsapp://send?text=' + shareText;
                window.open(shareUrl, '_blank');
            });

            // Share on Facebook
            $('.social--facebook').on('click', function () {
                let shareUrl = 'https://www.facebook.com/sharer/sharer.php?u=' + window.location.href;
                window.open(shareUrl, '_blank');
            });

            // Share on Twitter
            $('.social--x').on('click', function () {
                let shareUrl = 'https://twitter.com/intent/tweet?url=' + window.location.href;
                window.open(shareUrl, '_blank');
            });
        },

        translate_btn: function () {
            let modalOpen = false;

            let translate_btn = '<div class="post--translate-container"><div class="post--translate-btn"><i class="icon icon-lang"></i></div></div>';
            let language_list = '<div class="post--language-list" style="display: none;"><div class="translate--close translate--btn"><i class="icon icon-close-alt"></i></div><div class="languages"><ul>\n' +
                '  <li lang="en">Englisch</li>\n' +
                '  <li lang="fr">Französisch</li>\n' +
                '  <li lang="es">Spanisch</li>\n' +
                '  <li lang="it">Italienisch</li>\n' +
                '  <li lang="ar">Arabisch</li>\n' +
                '  <li lang="tr">Türkisch</li>\n' +
                '  <li lang="uk">Ukrainisch</li>\n' +
                '  <li lang="zh">Chinesisch</li>\n' +
                '  <li lang="pl">Polnisch</li>\n' +
                '  <li lang="ro">Rumänisch</li>\n' +
                '</ul>\n</div></div>'
            breadcrumbs.append(translate_btn);

            $('.post--translate-container').append(language_list);

            $('.translate--close').on('click', function () {
                if (modalOpen) {
                    $('.post--popup').remove();
                    $('.main--header').css('z-index', '0');
                    $('.post--translate-container').css('z-index', '0');
                    modalOpen = false;
                    $('.post--language-list').hide();
                    return;
                }
            });

            $('.post--translate-btn').on('click', function () {
                if (modalOpen) {
                    $('.post--popup').remove();
                    $('.main--header').css('z-index', '0');
                    $('.post--translate-container').css('z-index', '0');
                    modalOpen = false;
                    $('.post--language-list').hide();
                    return;
                } else {
                    $('main').append('<div class="post--popup"></div>');
                    $('.main--header').css('z-index', '1');
                    $('.post--translate-container').css('z-index', '1000');
                    $('.post--language-list').show();
                    modalOpen = true;
                }
            });

        },

        post_time_ago: function () {
            let time = post_time.find('time').attr('datetime');
            let time_ago = _ARTICLE.timeago(new Date(time));
            post_time_ago.html(time_ago);
        },

        mobile_swiper: function () {

            const swiper = new Swiper('.post--mobile-section', {
                speed: 400,
                spaceBetween: 10,
                slidesPerView: 1.2,

                pagination: {
                    el: '.swiper-pagination',
                    type: 'bullets',
                },
            });

        }


    };

    if (window.ONBN.modules.article === true) {
        require_article();
    }


})(jQuery);