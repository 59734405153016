(function ($) {
    "use strict";

    let _POLL = null;


    let poll_require = function () {
        if (typeof window.localStorage !== "undefined") {
            _poll_core.init();
        } else {
            // Check again after a short delay
            setTimeout(poll_require, 50);
        }
    };


    const $pollContainer = $('.js--poll').length ? $('.js--poll') : null;
    let post = $('article.post').length && $('article.post').attr('id') ? $('article.post').attr('id').replace('post-', '') : null;
    let results_container = $('.poll_results li').length ? $('.poll_results li') : null;
    let _title = $('.post--title').length ? $('.post--title').text() : '';

    let submitted_now = false;

    let _poll_core = {
        init: function () {
            _POLL = this;
            if ($pollContainer) {
                this.submit_poll();
                this.check_status();
                this.show_results();

                this.add_answer_to_url();
            }
        },

        submit_poll: function () {

            $('.js--poll button').on('click', function () {

                if ($.isFunction(window.callAnalytics)) {
                    callAnalytics("poll :: " + _title);
                }

                let answer = $(this).data('answer');
                let post_id = $(this).data('post');

                // Add 'is-processing' class to indicate the process is ongoing

                $pollContainer.addClass('is-processing');


                let postData = {
                    action: 'submit_poll',
                    post_id: post_id,
                    poll_answer: answer,
                };

                // Check and update poll-posts in localStorage
                let pollPosts = JSON.parse(localStorage.getItem('poll-posts')) || {};
                pollPosts[post_id] = answer; // Update or add the new answer
                submitted_now = true;
                localStorage.setItem('poll-posts', JSON.stringify(pollPosts));

                $.post(ajax_poll.ajaxurl, postData, function (response) {
                    if (response.success) {
                        location.reload();
                    } else {
                        alert('There was an error: ' + response.data);
                    }
                });
            });


        },

        add_answer_to_url: function () {
            let pollPosts = JSON.parse(localStorage.getItem('poll-posts')) || {};
            let answer = pollPosts[post];

            if (answer) {
                window.location.hash = answer;
            }
        },

        hashchange: function () {
            let hash = window.location.hash.substring(1); // Remove the '#' character

            if (!hash) return; // Exit if no hash is present

            results_container.each(function () {
                // Reset opacity for all poll result bars
                $(this).children('.poll_result_bar').css('opacity', 0.5);
            });

            // Highlight the correct answer by changing its opacity
            let selectedIndex = parseInt(hash) - 1; // Convert hash to 0-based index
            if (selectedIndex >= 0 && selectedIndex < results_container.length) {
                results_container
                    .eq(selectedIndex)
                    .children('.poll_result_bar')
                    .css('opacity', 1); // Set full opacity for the correct answer
            }
        },


        show_results: function () {


            results_container.each(function () {
                let result = $(this).data('result');


                $(this).children('.poll_result_bar').css('width', result + '%');


            });


            if (submitted_now) {
                $(".total-voted").text(function () {
                    return parseInt($(this).text()) + 1;
                });
            }


            _POLL.hashchange();

            window.addEventListener('hashchange', function () {
                    _POLL.hashchange();
                }
            );

        },

        check_status: function () {
            let pollPosts = JSON.parse(localStorage.getItem('poll-posts')) || {};
            let currentPostId = $pollContainer.find('button').data('post');

            if (pollPosts[currentPostId]) {
                $pollContainer.addClass('is-done');
            }
        }
    };

    if (window.ONBN.modules.poll === true) {
        poll_require();
    }


})(jQuery);