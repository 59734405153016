(function ($) {
    "use strict";

    let _SANTA = null;

    let _santa_core = {
        init: function () {
            _SANTA = this;
            this.santapress();
        },

        santapress: function () {
            $(document).on("click", ".santapress-door", function () {
                callAnalytics("Santa :: Tür geöffnet");
            });

            $(document).on("click", ".santa-btn .button", function (e) {
                // Get today's date
                let today = new Date();
                let dd = String(today.getDate()).padStart(2, "0");
                let mm = String(today.getMonth() + 1).padStart(2, "0"); // January is 0!
                let yyyy = today.getFullYear();
                let formattedToday = yyyy + "-" + mm + "-" + dd;

                // Get the date from the data-date attribute and format it for comparison
                let buttonDate = $(this).data("date");
                let parts = buttonDate.split(".");
                let formattedButtonDate = parts[2] + "-" + parts[1] + "-" + parts[0];

                // Convert both dates to Date objects for comparison
                let todayDate = new Date(formattedToday);
                let btnDate = new Date(formattedButtonDate);

                console.log(buttonDate);

                // Compare the dates and act accordingly
                if (todayDate.getTime() === btnDate.getTime()) {
                    // If dates match, redirect to the URL in data-url
                    window.location.href = $(this).data("url");
                } else if (todayDate.getTime() < btnDate.getTime()) {
                    // If button date is before today, show the early message
                    $(this).hide();
                    $(this)
                        .parent()
                        .append(
                            "<p>Sie sind aber sehr früh dran heute - die Gewinner:innen sind noch nicht gezogen worden. Versuchen Sie doch in der Zwischenzeit Ihr Glück mit dem Türchen des heutigen Tages. Aber bleiben Sie dran, die Gewinner:innen werden bald bekannt gegeben – vielleicht ist heute tatsächlich Ihr Glückstag!</p>"
                        );
                } else {
                    // If button date is after today, show the late message
                    $(this).hide();
                    $(this)
                        .parent()
                        .append(
                            "<p>Es ist leider zu spät - dieses Türchen wurde bereits geschlossen. Aber keine Sorge, schauen Sie sich doch das Türchen des heutigen Tages an. Vielleicht haben Sie heute Glück!</p>"
                        );
                }
            });
        },

    };

    if (window.ONBN.modules.santapress === true) {
        _santa_core.init();
    }

})(jQuery);