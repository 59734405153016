(function ($) {
    "use strict";

    let region_require = function () {
        if (typeof window.Cookies !== "undefined") {
            _region_core.init();
        } else {
            // Check again after a short delay
            setTimeout(region_require, 50);
        }
    };


    let _REGION = null;

    let _region_core = {
        init: function () {
            _REGION = this;
            this.setup();
            this.events();
        },

        events: function () {
            $(".js--region").on("click", function () {
                $("body").find(".region--popup-container").addClass("is-open");
            });

            $(document).on("keydown", function (event) {
                if (event.key === "Escape" || event.keyCode === 27) {
                    // Check for the Escape key
                    $(".region--popup-container").removeClass("is-open");
                }
            });

            $(document).on("click", ".region--popup-container.is-open",
                function (event) {
                    if (event.target === this) {
                        // Direct click on the container
                        $(this).removeClass("is-open");
                    }
                }
            );

            $(".js--region-select").on("click", function () {
                // Set the cookie
                Cookies.set("weather", $(this).data("id"), {expires: 7}); // Here, the cookie expires in 7 days. Adjust as needed.

                // Redirect to the URL
                window.location.href = $(this).data("url");
            });
        },


        setup: function () {


            // Reading the 'data-start' attribute
            let regions = $(".region--popup-container").data("start");

            // Reading the 'weather' cookie
            let selectedRegion = Cookies.get("weather");

            if (regions[selectedRegion]) {
                // Change the URLs of the logos
                $(".header--logo .logo--light, .header--logo .logo--dark").attr(
                    "href",
                    regions[selectedRegion].url
                );
            }

            if ($(".weather--widget").length) {
                // Get the weather data from the HTML element
                let weatherData = $(".weather--widget").data("weather");

                // Get the weather cookie
                let weatherCookie = Cookies.get("weather");

                //console.log(Object.keys(weatherData));
                // Determine the city to be used (either from the cookie or the first city from the JSON array)
                let selectedCityKey =
                    weatherCookie && weatherData[weatherCookie]
                        ? weatherCookie
                        : Object.keys(weatherData)[0];
                let selectedCity = weatherData[selectedCityKey];

                // Set the city, temperature, and icon data
                $(".weather--tempo span").text(selectedCity.temp); // Assuming the temperature is in Celsius
                $(".weather--icon i").attr("class", "icon icon-" + selectedCity.icon);
                $(".weather--city").text(selectedCity.city);
            }
        }

    };

    if (window.ONBN.modules.region === true) {
        region_require();
    }

})(jQuery);

