(function ($) {
    "use strict";
    let require_gallery_slider = function () {
        if (typeof Swiper !== "undefined") {
            _gallery_slider.init();
        } else {
            // Check again after a short delay
            setTimeout(require_gallery_slider, 50);
        }
    };


    let _GS = null;
    let _gallery_slider = {

        __Selector: ".js--gallery-swiper",

        init: function () {

            if (window.ONBN.modules.gallery) {
                _GS = this;
                _GS.setup();
                _GS.events();
            }
        },

        setup: function () {
            document.querySelectorAll(_GS.__Selector).forEach(_GS.build);
        },


        build: function (item) {

           let galleryTitle = $('body').find('h1').text() || "Kein Titel";


            let GallerySlider = new Swiper(item, {
                cssMode: true,
                slidesPerView: 1,
                freeMode: false,
                loop: true,
                draggable: true,
                keyboard: {
                    enabled: true,
                    onlyInViewport: true // Only activate keyboard control when the swiper is in viewport
                },
                /* effect: "fade",*/
                autoHeight: true,
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev"
                },
                pagination: {
                    el: ".swiper-pages",
                    type: "fraction"
                }

            });

            /**
             * Calling Analytics on slide change
             */
            GallerySlider.on("realIndexChange", function(slide) {
               if (typeof window.callAnalytics === "function")
                 callAnalytics(galleryTitle+ " | " + (slide.realIndex + 1));
                GallerySlider.updateAutoHeight();
             });


            let advertIndexes = [];
            let triggeredAdverts = [];

            GallerySlider.slides.forEach((slide, index) => {
                if (slide.classList.contains("slide-advert")) {
                    // Use Swiper's internal method to get the actual index
                    const realIndex = slide.getAttribute("data-swiper-slide-index");
                    advertIndexes.push(parseInt(realIndex));
                }
            });

            console.log("Advert indexes:", advertIndexes);

            function checkProximityToAdverts(currentIndex) {

                if (
                    (window.adsdefinition &&
                        window.adsdefinition._gallery &&
                        window.adsdefinition._gallery.is_active &&
                        Array.isArray(window.adsdefinition._gallery.type) &&
                        window.adsdefinition._gallery.type.includes("slider"))
                    ||
                    // Legacy Tagman.js
                    typeof advertising !== "undefined"
                ) {

                    advertIndexes.forEach(advertIndex => {
                        const distance = advertIndex - currentIndex;

                        // Check if the advert is within the next 2 or previous 2 slides
                        if ((distance > 0 && distance <= 2) || (distance < 0 && distance >= -2)) {
                            if (!triggeredAdverts.includes(advertIndex)) {
                                // Find the element with the class "advert" inside the advert slide
                                const advertSlide = Array.from(GallerySlider.slides).find(slide => {
                                    return parseInt(slide.getAttribute("data-swiper-slide-index")) === advertIndex;
                                });

                                if (advertSlide) {
                                    let advertElement = advertSlide.querySelector(".advert");

                                    if (advertElement) {
                                        let advertId = advertElement.id;
                                        console.log(`You are close to an advert at index ${advertIndex}, with element ID: ${advertId}.`);
                                        window.dispatchEvent(
                                            new CustomEvent("advert__gallery_slider", {
                                                detail: {ID: advertId}
                                            }));

                                    } else {
                                        console.log(`You are close to an advert at index ${advertIndex}, but no element with the class "advert" was found.`);
                                    }

                                    // Mark this advert as triggered
                                    triggeredAdverts.push(advertIndex);
                                }
                            }
                        }
                    });

                } else {
                    console.log("No adverts found in the gallery.");
                }

            }


            // Step 2: Call the function at the start of the slider
            checkProximityToAdverts(GallerySlider.realIndex);

            // Step 3: Detect when close to an advert index during slide change
            GallerySlider.on("realIndexChange", function () {
                checkProximityToAdverts(GallerySlider.realIndex);
            });


        },


        events: function () {
            // Add your events here
        }


    };

    require_gallery_slider();


})(jQuery);