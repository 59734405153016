(function ($) {
    "use strict";

    let awo_require = function () {
        _awo_core.init();
    };

    const $_image_url = "https://www.sol.de/wp-content/uploads/AWO100.gif";
    const $_post_url =
        "https://www.sol.de/lesetipps/100-jahre-awo-saarland-jetzt-gewinnen,502316.html";
    const $_utm_source = "sol";
    const $_utm_medium = "website";
    const $_utm_campaign = "100-jahre-awo-saarland-jetzt-gewinnen";
    const $_show_probability = 100; // Set the probability (1-100) of showing the image


    let _AWO = null;

    let _awo_core = {
        init: function () {
            _AWO = this;
            this.setup();
        },


        insertPkeyIntoForm: function () {
            const urlParams = new URLSearchParams(window.location.search);
            const pkey = urlParams.get("pkey");
            if (!pkey) return;

            const intervalId = setInterval(() => {
                const $inputField = $("div.wpforms-field.pkey input");
                if ($inputField.length > 0) {
                    $inputField.val(pkey);
                    console.log("Game :: PKEY INSERTED");
                    clearInterval(intervalId);
                }
            }, 100); // Check every 100ms
        },


        shouldShowImage: function (probability) {
            return Math.random() * 100 < probability;
        },

        insertBackgroundImage: function () {
            const $postContent = $(".post--wysiwyg");
            if ($postContent.length === 0) return;

            const $paragraphs = $postContent.children("p").filter(function () {
                return $.trim($(this).text()).length > 0;
            });

            // On Small Article doesn't make sense
            if ($paragraphs.length < 3) return;

            const targetIndex =
                Math.floor(Math.random() * ($paragraphs.length - 3)) + 2; // Between 2nd and 2nd last <p>
            const $targetP = $paragraphs.eq(targetIndex);

            const $image = $("<img />", {
                src: $_image_url,
                css: {
                    position: "relative",
                    width: "300px",
                    height: "250px",
                    float: "right",
                    margin: "10px",
                    overflow: "hidden",
                    cursor: "pointer"
                }
            });

            if (_awo_core.shouldShowImage($_show_probability)) {
                console.log("Game :: IS WIN");
                $targetP.before($image);
            } else {
                console.log("Game :: IS LOSE");
            }

            $image.on("click", function () {
                const pkey = window.ONS.generateRandomHash(32);
                const utmParams = `?utm_source=${$_utm_source}&utm_medium=${$_utm_medium}&utm_campaign=${$_utm_campaign}&pkey=${pkey}`;
                window.location.href = $_post_url + utmParams;
            });
        },

        setup: function () {
            // Check if the domain is sol.de
            if (!window.ONS.Domain("sol.de")) return;

            _awo_core.insertPkeyIntoForm();

            if ($("body").hasClass("stop-support")) return;

            console.log("Game :: IS ON");

            _awo_core.insertBackgroundImage();
        },

    };

    if (window.ONBN.modules.awo === true) {
        awo_require();
    }

})(jQuery);

