(function ($) {
    "use strict";


    let _SEARCH = null;

    let _search_core = {
        init: function () {
            _SEARCH = this;
            this.setup();
        },

        setup: function () {
            $(".js--search, .js-m--search-trigger").on("click", function () {
                $("body").find(".search--popup-container").addClass("is-open");
                setTimeout(function () {
                    var input = $("body").find(".search--popup-container #search_text");
                    input.focus();
                }, 300);
            });

            // Close search popup on clicking the close button
            $(".js--search-close").on("click", function () {
                $("body").find(".search--popup-container").removeClass("is-open");
            });

            // Close search popup on "Esc" key press
            $(document).on("keydown", function (event) {
                if (event.key === "Escape" || event.keyCode === 27) {
                    // Check for the Escape key
                    $(".search--popup-container").removeClass("is-open");
                }
            });

            // Close search popup on clicking outside of its children
            $(document).on(
                "click",
                ".search--popup-container.is-open",
                function (event) {
                    if (event.target === this) {
                        // Direct click on the container
                        $(this).removeClass("is-open");
                    }
                }
            );
        },
    }

    if (window.ONBN.modules.search === true) {
        _search_core.init();
    }

})(jQuery);